.agxano__monitoring {

    .agxano__header {
        @include background("../images/monitoring-bg-hero.jpg");
        position: relative;


        .height-helper {
            min-height: 30.8125rem;
        }
    }

    .agxano__header_overlay {
        background-color: rgba(0,0,0,0.71);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .agxano__scalable-solution {
        min-height: 24.5625rem;

        @include media-breakpoint-up(lg) {
            margin: 10rem 0;
        }

        ul {
            color: $monitoring-list-color;
        }
    }

    .agxano__monitoring-cards {

        small {
            font-size: 0.875rem;
        }

        .card-body {
            @include media-breakpoint-down(lg) {
                padding: 1.25rem;
            }
        }
    }
}