@import "../generic/generic";

.agxano__home {

    .agxano__header {
        @include background("../images/hero-bg.jpg");

        position: relative;

        .height-helper {
            min-height: 47.6875rem;
        }
    }

    .agxano__header_overlay {
        background-color: rgba(0,0,0,0.71);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .agxano__our-technology__cards {

        @include media-breakpoint-only(md) {

            .description {
                font-size:   15px;
                line-height: 27px;
            }
        }
    }

    .agxano__centralized-dashboard {

        .height-helper {
            min-height: 48.75rem;
        }

        @include media-breakpoint-up(lg) {

            img {
                transform: scale(1.4);
            }
        }

        .btn-primary {

            &:hover {
                box-shadow: 0 1.25rem 2.8125rem 0 $home-centralized-btn-primary-hover;
            }
        }
    }

    .agxano__led {
        position:                relative;
        min-height:              60.625rem;
        width:                   100%;
        height:                  50%;
        background-image:        url('../images/led-bg.jpg');
        -webkit-background-size: 100% 56%;
        -moz-background-size:    100% 56%;
        -o-background-size:      100% 56%;
        background-size:         100% 56%;
        background-position:     top center;
        background-repeat:       no-repeat;

        .scrollbar-helper {
            overflow-x: scroll;
        }

        .led-cards {

            .slick-slide {
                display: inline-block;

                @include media-breakpoint-up(lg) {
                    margin-right: 3.875rem;
                }
            }

            .led-item {
                cursor: move;
                cursor: grab;
                cursor: -moz-grab;
                cursor: -webkit-grab;
                max-width: 22.5rem;

                .img-placeholder {
                    min-height:    30.4375rem;
                    position:      relative;
                    border-radius: 0.3125rem;
                    background:    linear-gradient(180deg, rgba(254, 254, 254, 0.57) 0%, #FBFBFB 100%);
                    box-shadow:    0 0 1.875rem 0 rgba(225, 225, 225, 0.8);

                    img {
                        opacity: 1;
                        @include transition();
                    }

                    .layer-top {
                        position:      absolute;
                        top:           0;
                        left:          0;
                        right:         0;
                        bottom:        0;
                        width:         100%;
                        height:        100%;
                        opacity:       0;
                        border-radius: 0.3125rem;
                        @include transition();
                    }

                    &.hover-overlay {

                        img {
                            opacity: 0;
                        }

                        .layer-top {
                            opacity: 1;
                        }
                    }
                }

                @extend %outline-helper;

                &:active {
                    cursor: grabbing;
                    cursor: -moz-grabbing;
                    cursor: -webkit-grabbing;
                }

                a {
                    @extend %outline-helper;
                }
            }
        }

        .overlay {
            height:     60.625rem;
            width:      10rem;
            position:   absolute;
            right:      0;
            top:        0;
            background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 56%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 56%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 56%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter:     progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
        }
    }
}
