.agxano__get-started {
    @include background("../images/get-started.jpg");
    border-bottom: 0.3125rem solid $topaz;

    position: relative;
    background-position: center;


    .height-helper {
        min-height: 21.3125rem;
    }
}

.agxano__get-started-overlay {
    background-color: rgba(38,76,202,0.77);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
