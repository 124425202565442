.agxano__commercial {

    .commercial-card {
        max-width:     21rem;
        min-height:    27.75rem;
        border-radius: 0.3125rem;
        border:        0.0625rem solid $commercial-card-border-color;
        @include transition($timing: 0.1s);

        @include media-breakpoint-up(md) {
            max-width: 17.5rem;
        }

        &:hover {
            border-bottom: 0.3125rem solid $primary;
            box-shadow:    0 0 1.875rem 0 $commercial-card-hover-color;
        }
    }
}
