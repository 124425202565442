.agxano__horticulture {

    .agxano__horticulture-header {
        background-color: $horticulture-header-bg-color;
        padding-top:      15.8125rem;

        h1 {
            line-height: 5.3125rem;
        }

        @include media-breakpoint-up(lg) {
            min-height: 81.75rem;

            img {
                transform: scale(1.2);
                position:  absolute;
                right:     5rem;
                width:     95%;
            }
        }

        button {
            padding: 1rem 3.7875rem;
        }

        .horticulture-info {
            margin-top: 5.0625rem;

            @include media-breakpoint-up(lg) {
                margin-top: 20.0625rem;
            }
        }
    }

    .agxano__connected-platform {
        margin-top: 7.0625rem;
    }

    .agxano__commercial {

        .height-helper {
            min-height: 18rem;
        }

        .slick-slide {
            display: inline-block;

            @extend %outline-helper;
        }

        .flip-card {
            background-color: transparent;
            perspective:      62rem;
            height:           17.5rem;
            border-radius:    0.3125rem;

            @include media-breakpoint-up(lg) {
                width: 17.5rem;
            }

            &:hover {
                .flip-card-inner {
                    transform: rotateX(180deg);
                }
            }
        }

        .flip-card-inner {
            position:        relative;
            transition:      transform 0.6s;
            transform-style: preserve-3d;
            border:          0.0625rem solid $horticulture-flip-card-border-color;
            width:           17.5rem;
            height:          17.5rem;
            border-radius:   0.3125rem;
        }

        .flip-card-front, .flip-card-back {
            position:                    absolute;
            width:                       100%;
            height:                      100%;
            -webkit-backface-visibility: hidden;
            backface-visibility:         hidden;
            border-radius:               0.3125rem;
        }

        .flip-card-back {
            transform:        rotateX(180deg);
            background-color: $primary;
        }

        .right-arrow, .left-arrow {
            top:     7rem;
            display: none;

            @media only screen and (min-width: 1330px) {
                display: block;
            }

            path {
                fill: $primary
            }

            &:hover {
                cursor: pointer;

                path {
                    fill: $horticulture-flip-card-arrow-hover-fill-color;
                    @include transition($timing: 0.1s);
                }
            }
        }

        .left-arrow {
            left: -4rem;
        }

        .right-arrow {
            right:     -3rem;
            transform: rotate(180deg);
        }
    }

    .agxano__horticulture-visibility {
        background: $primary;

        @include media-breakpoint-up(lg) {
            @include background("../images/horticulture-centralized.jpg");
        }

        .height-helper {
            min-height: 42.75rem;
        }

        ul {
            color: $horticulture-visibility-list-color;
        }
    }
}
