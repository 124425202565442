.agxano__contact {

    .agxano__header {
        @include background("../images/contact-hero.jpg");

        .height-helper {
            min-height: 30.8125rem
        }

        .paragraph {
            color: $black;
        }
    }
}
