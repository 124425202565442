@mixin background($imgPath, $size: cover, $position:0 0, $repeat: no-repeat) {
    background: {
        image:    url($imgPath);
        position: $position;
        repeat:   $repeat;
        size:     $size;
    }
}

@mixin transition($timing: 0.4s, $easing: ease-in-out) {
    -moz-transition:    all $timing ease-in-out 0s;
    -webkit-transition: all $timing ease-in-out 0s;
    -ms-transition:     all $timing ease-in-out 0s;
    transition:         all $timing ease-in-out 0s;
}
