.agxano__connected-platform {

    .slick-carousel {
        min-height: 4.57rem;
    }

    .connected-platform-accordions {

        .slick-arrow {
            position: absolute;
            right:    0;
            cursor:   pointer;

            svg line, svg use {
                @include transition(0.2s);
            }

            &:not(.slick-disabled) {

                svg {

                    line {
                        stroke: $primary;
                        @include transition(0.2s);
                    }

                    use {
                        fill: $primary;
                        @include transition(0.2s);
                    }
                }
            }
        }

        .top-arrow {
            top: 5rem;
        }

        .bottom-arrow {
            top:       17rem;
            transform: rotate(180deg);
        }

        .platform-accordion {
            width:         80%;
            margin-bottom: 0.8rem;

            a {
                position: relative;

                &:after {
                    height:       0.0625rem;
                    display:      block;
                    background:   $connected-platform-accordion-bg-color;
                    border-right: 0.0625rem white;
                    content:      '';
                }

                h3 {
                    padding-bottom: 0.7625rem;
                    font-weight:    normal;
                    color:          $connected-platform-h3-color;
                }

                .side-number {
                    padding:  0;
                    margin:   0;
                    position: absolute;
                    left:     -3rem;
                    top:      0;
                }

                &[aria-expanded="true"] {
                    border-top-color: $primary;

                    h3 {
                        font-weight: bold;
                        color:       $primary;
                        @include transition(0.2s);
                    }

                    &:after {
                        background: $primary;
                        height:     0.125rem;
                    }
                }
            }

            .description {
                padding-top: 1.5rem;
                color:       $connected-platform-description-color;
            }
        }
    }
}

.agxano__solutions {

    .connected-platform-accordions {

        .platform-accordion {
            width: 100%;
        }
    }
}