.agxano__solutions {

    .agxano__header {
        @include background("../images/solutions-header.jpg");

        .height-helper {
            min-height: 30.8125rem;
        }
    }

    .agxano__yield {

        .height-helper {
            min-height: 28.125rem;
        }

        .description {
            line-height: 2.25rem;
        }
    }

    .agxano__solution-products {
        min-height:       50rem;
        background-color: $solution-products-bg-color;

        .divider {
            flex-grow:     1;
            border-bottom: 0.0625rem solid $gray-400;
            margin:        2.3rem;
        }

        .nav {
            min-height: 10.75rem;
        }

        .nav-tabs .nav-link {
            font-size: 1.5rem;
            color:     $solution-products-nav-link-color;

            &:after {
                content:    '';
                display:    block;
                width:      0;
                height:     0.1875rem;
                background: $primary;
                transition: width .1s;
            }

            &.active {
                border-bottom: none !important;
                font-weight:   bold;
                color:         $black;

                &:after {
                    @include transition(0.1s);
                    width: 100%;
                }
            }
        }

        .slider-nav-thumbnails {

            .thumb {
                border:        0.0625rem solid $gray-400;
                border-radius: 0.25rem;
                min-height:    4.5rem;
                min-width:     4.5rem;
                display:       flex;
                align-items:   center;
                margin-bottom: 1.375rem;

                &:hover {
                    border: 0.0625rem solid $primary;
                    @include transition(0.2s);
                }

                @extend %outline-helper;
            }
        }

        .slick-carousel {

            .slick-slide {
                height:              15rem;
                min-width:           16rem;
                background-repeat:   no-repeat;
                background-size:     contain;
                background-position: center;

                @include media-breakpoint-up(md) {
                    min-width: 28.125rem;
                }

                @extend %outline-helper;
            }
        }

        .btn-link {
            &:hover {
                .icon {

                    &.icon-arrow {
                        background-image: url("../images/arrow-right-black.svg");
                    }
                }
            }

            .icon {

                &.icon-arrow {
                    background-image: url("../images/arrow-right-black.svg");
                    right:            1.3125rem;
                }
            }
        }
    }

    .agxano__uv {
        background-color: $primary;

        .height-helper {
            min-height: 12rem;
        }

        h3 {
            color: $white;
        }

        h3, .description, .display-2 {
            text-align: left;
        }

        .description {
            color: $solutions-uv-description-color;
        }

        img {
            left: -6rem;
        }
    }

    .agxano__spectrum {

        .description {
            line-height: 2.25rem;
        }
    }

    .agxano__grow {

        .display-1 {
            font-size:   3.125rem;
            line-height: 4.0625rem;
        }

        .description {
            font-size:   1.5rem;
            line-height: 2.5rem;
        }

        .height-bg-helper {
            background-repeat:   no-repeat;
            background-size:     cover;
            background-position: center;
            min-height:          34.4375rem;
        }
    }
}
