.agxano__about {

    .agxano__header {
        @include background("../images/about-bg-hero.jpg");

        span {
            color: $about-header-secondary;
        }

        .height-helper {
            min-height: 30.8125rem;
        }
    }

    .agxano__about-info {

        .description {
            line-height: 2.25rem;
        }
    }

    .agxano__our-technology__cards {

        .tech-card {
            border-bottom: none;
            min-height:    17.875rem;

            @include media-breakpoint-up(md) {
                min-height: 22.875rem;
            }
        }

        .description {
            color: $about-our-technology-description;
        }
    }

    .contact-form {
        align-items: center;
    }
}