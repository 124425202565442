//
// Grid breakpoints
//
$grid-breakpoints:                  (
                                            xs: 0,
                                            sm: 576px,
                                            md: 768px,
                                            lg: 992px,
                                            xl: 1200px
                                    );

$container-max-widths:              (
                                            sm: 540px,
                                            md: 720px,
                                            lg: 960px,
                                            xl: 1140px,
                                            xxl: 1200px,
                                    );

//
// Color system
//
$body-bg:                           #ffffff;
$body-color:                        #181818;

$primary:                           #2e5bff;
$bg-primary:                        #fff;
$dark:                              #0f0d18;
$gray-300:                          #dbdbdb;
$gray-400:                          #9797974d;
$gray-500:                          #cccccc;
$gray-700:                          #bfc5d2;
$gray-text:                         #b2b2b2;
$input-bg-gray:                     #F4F6F9;
$input-color-text:                  #AAB4C4;
$input-focus-color:                 #0F0D18;
$topaz:                             #07F5B8;
$black:                             #000000;
$white:                             #ffffff;
$error:                             #FF0000;
$card-bg-color:                     #e0e5ef33;

//
// Typography
//
$font-size-base:                    1rem;
$line-height-base:                  1.0625;
$letter-spacing-base:               0;
$font-family-base:                  ProximaNova, sans-serif;
$font-size-base:                    1rem;
$line-height-base:                  1.625;
$font-weight-bold:                  600;

//$font-weight-base: 300;
$font-weight-normal:                400;
//$font-weight-bolder: 600;

$hr-border-color:                   rgba($white, .15);
$hr-margin-y:                       0;

//
// Headings
//
$h1-font-size:                      $font-size-base * 3.4375;
$h2-font-size:                      $font-size-base * 2.1875;
$h3-font-size:                      $font-size-base * 1.5;
$h4-font-size:                      $font-size-base * 1.25;

$headings-font-family:              $font-family-base;
//$headings-font-weight: $font-weight-bold;
$headings-margin-bottom:            $font-size-base * 4.5;
$headings-color:                    $black;

// display
$display1-size:                     $h1-font-size;
$display2-size:                     $h2-font-size;
$display3-size:                     $h3-font-size;
$display4-size:                     $h4-font-size;

$display1-weight:                   $font-weight-bold;

//
// Paragraphs
$paragraph-margin-bottom:           2.5rem;

//
// Links
//
$link-color:                        $primary;
$link-decoration:                   none;
$link-hover-color:                  darken($primary, 40%);
$link-hover-decoration:             none;

//
// Navbar
//
$navbar-light-color:                $white;
$nav-link-padding-y:                0;
$nav-link-padding-x:                0;
$navbar-light-active-color:         $primary;
$navbar-light-hover-color:          darken($navbar-light-color, 0.2);

$navbar-nav-link-padding-x:         1.5625rem;

$navbar-padding-x:                  0;

$nav-tabs-border-color:             transpartent;
$nav-tabs-border-width:             0;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  transparent;
$nav-tabs-link-active-color:        $black;
$nav-tabs-link-active-bg:           none;
$nav-tabs-link-active-border-color: transparent;

//
// Buttons
//
$btn-padding-y:                     0.625rem;
$btn-padding-x:                     3.875rem;
$btn-font-family:                   $font-family-base;
$btn-font-size:                     0.9375rem;
$btn-line-height:                   1.3125rem;
$btn-font-weight:                   $font-weight-bold;
$btn-border-radius:                 0.25rem;
$btn-border-width:                  0.125rem;

//
// Cards
//
$card-border-radius:                0.3125rem;
$card-color:                        $dark;

//
// Grid columns
//
$grid-gutter-width:                 2.625 ‬rem;

//
// Spacer
//
$spacer:                            1rem;
$spacers:                           ();
$spacers:                           map-merge((
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 4),
        7: ($spacer * 5),
        8: ($spacer * 6),
        9: ($spacer * 7),
        10: ($spacer * 8)
), $spacers);

// Components
//
// Define common padding and border radius sizes and more.
$border-width:                      0.0625rem;

// Forms
$form-grid-gutter-width:            1rem;
$form-group-margin-bottom:          0.875rem;

$input-padding-y:                   1.25rem;
$input-padding-x:                   1.6875rem;
$input-font-size:                   1.125rem;

$input-bg:                          $input-bg-gray;

$input-color:                       $input-focus-color;

$input-border-radius:               0.3125rem;

$input-focus-color:                 $input-focus-color;
$input-focus-border-color:          $primary;
$input-placeholder-color:           $input-color-text;

$input-btn-focus-width:             0.125rem;
$input-btn-focus-color:             $primary;

$input-btn-border-width:            0;

$custom-select-color:               $input-color-text;
$custom-select-indicator-color:     $input-color-text;
$custom-select-indicator-padding:   0;
$custom-select-indicator:           url("data:image/svg+xml,<svg width='20px' height='11px' viewBox='0 0 20 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> <g id='01-Final' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'> <g id='03-Contact' transform='translate(-1391.000000, -892.000000)' stroke='#AAB4C4' stroke-width='2'> <g id='Group-8' transform='translate(840.000000, 719.000000)'> <polyline id='Path-2-Copy-2' points='552 174 561 182 570 174'></polyline> </g> </g> </g></svg>");
$custom-select-bg-size:             auto;

// Cards

$card-border-width:                 0;
$card-border-radius:                0.5rem;
$card-height:                       45rem;
$card-bg:                           $card-bg-color;

$card-group-margin:                 1.25rem;
$card-deck-margin:                  $card-group-margin !default;
