.agxano__footer {
    background-color: $footer-bg-color;

    .dimmed {
        opacity: 0.2;
    }

    .height-helper-top {
        min-height: 10rem;
    }

    .height-helper-bottom {
        min-height: 6.75rem;
    }

    ul li {
        position: relative;

        &.list-inline-item {
            margin-right: 0.7rem;
        }

        a {
            padding-bottom: 0.5rem;
            color:          $white;

            &:hover {

                &:after {
                    @include transition(0.1s);
                    width: 100%;
                }
            }

            &:after {
                content:    '';
                display:    block;
                width:      0;
                height:     1px;
                background: $topaz;
                transition: width .1s;
            }

            &:before {
                content:      '';
                position:     absolute;
                height:       0.9375rem;
                top:          0.65rem;
                border-right: 0.0625rem solid $white;
                right:        -0.5rem;
            }
        }

        &:last-child {

            a {
                &:before {
                    content: none;
                }
            }
        }
    }

    .social-links {

        .list-inline-item {

            &:hover {

                path {
                    @include transition(0.1s);
                    fill: $topaz;
                }
            }

            a {
                &:before {
                    content: none;
                }

                &:after {
                    content: none;
                }
            }
        }
    }
}
