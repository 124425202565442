* {
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@include media-breakpoint-down(md) {
    :root {
        font-size: calc(1rem + ((1vw - 0.4rem) * 1.1111));
    }
}

@font-face {
    font-family:  ProximaNova;
    src:          local(ProximaNova-Regular),
                  url('/assets/fonts/proximanova-regular-webfont.woff2') format("woff2"),
                  url('/assets/fonts/proximanova-regular-webfont.woff') format("woff");
    font-weight:  normal;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family:  ProximaNova;
    src:          local(ProximaNova-Semibold),
                  url('/assets/fonts/proximanova-semibold-webfont.woff2') format("woff2"),
                  url('/assets/fonts/proximanova-semibold-webfont.woff') format("woff");
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family:  ProximaNova;
    src:          local(ProximaNova-Bold),
                  url('/assets/fonts/proximanova-bold-webfont.woff2') format("woff2"),
                  url('/assets/fonts/proximanova-semibold-webfont.woff') format("woff");
    font-weight:  bold;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family:  ProximaNovaA;
    src:          local(ProximaNovaA-Regular),
                  url('/assets/fonts/proxima_nova_alt_regular-webfont.woff2') format("woff2"),
                  url('/assets/fonts/proxima_nova_alt_regular-webfont.woff') format("woff");
    font-weight:  normal;
    font-style:   normal;
    font-stretch: normal;
}

.hero {
    font-family:    ProximaNova, sans-serif;
    font-size:      5rem;
    font-weight:    bold;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    0.81;
    letter-spacing: normal;
    text-align:     center;
    color:          $white;
}

h1, .display-1 {
    font-family:    ProximaNova, sans-serif;
    font-weight:    bold;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.18;
    letter-spacing: -0.0625rem;
    text-align:     center;
}

h2, .display-2 {
    font-family:    ProximaNova, sans-serif;
    font-weight:    600;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.43;
    letter-spacing: -0.0625rem;
    text-align:     center;
}

h3, .display-3 {
    font-family:    ProximaNova, sans-serif;
    font-weight:    600;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.67;
    letter-spacing: -0.0625rem;
    text-align:     center;
}

h4, .display-4 {
    font-family:    ProximaNova, sans-serif;
    font-weight:    normal;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    0.8;
    letter-spacing: normal;
}

.subtitle {
    font-family:    ProximaNova, sans-serif;
    font-size:      1.25rem;
    font-weight:    $font-weight-normal;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.7;
    letter-spacing: normal;
    text-align:     center;
    color:          $primary;
}

.paragraph {
    font-family:    ProximaNova, sans-serif;
    font-size:      1.25rem;
    font-weight:    normal;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.7;
    letter-spacing: normal;
}

.description {
    font-family:    ProximaNovaA, sans-serif;
    font-size:      1.125rem;
    font-weight:    normal;
    font-stretch:   normal;
    font-style:     normal;
    line-height:    1.875rem;
    letter-spacing: normal;
    color:          $description-color;
}

.text-primary {
    color: $primary;
}

.text-topaz {
    color: $topaz;
}
