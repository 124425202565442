.agxano__header {
    sup {
        font-size: 1.25rem;
        position:  absolute;
        top:       4px;
        right:     -16px;

        @include media-breakpoint-up(sm) {
            right: 27px;

            @include media-breakpoint-up(md) {
                right: 106px;
            }
        }
    }

    .paragraph {
        font-size:   1.25rem;
        font-family: ProximaNovaA, sans-serif;
        color:       $white;
        opacity:     0.7;
        margin-top:  2.5rem;
    }
}
