.btn {

    &.btn-outline-primary {

        &:hover {
            color: $white;
        }
    }

    &.btn-link {
        color:       $white;
        font-weight: $font-weight-bold;
        position:    relative;

        &:hover {
            color: $primary;

            .icon {

                &.icon-arrow {
                    background-image: url("../images/arrow-right-primary.svg");
                    transform:        translateX(0.7rem);
                }
            }
        }

        .icon {
            display:           inline-block;
            background-size:   contain;
            background-repeat: no-repeat;

            &.icon-arrow {
                width:            1rem;
                height:           0.875rem;
                background-image: url("../images/arrow-right.svg");
                transition:       all 500ms ease-out;
                right:            1.8125rem;
                top:              0.8125rem;
                position:         absolute;
            }
        }
    }

    &.btn-topaz {
        background-color: $topaz;
        border:           none;
    }
}
