//Colors
$smooth-black:                                  #0F0D18;
$gray-border-color:                             #69696921;

//Pages
$home-centralized-btn-primary-hover:            rgba(30, 114, 250, 0.3);

$horticulture-header-bg-color:                  #0C2145;
$horticulture-flip-card-border-color:           $gray-border-color;
$horticulture-flip-card-arrow-hover-fill-color: #083dff;
$horticulture-visibility-list-color:            #ffffff7a;

$monitoring-list-color:                         #848991;

$solution-products-bg-color:                    #e0e5ef33;
$solution-products-nav-link-color:              #0f0d1840;
$solutions-uv-description-color:                #ffffff99;

$about-header-secondary:                        #f8f9fa6b;
$about-our-technology-description:              $smooth-black;

//Layout
$webkit-scrollbar-track-color:                  #f1f1f1;
$webkit-scrollbar-thumb-bg-color:               #88888859;
$webkit-scrollbar-thumb-hover-color:            #55555559;

//Typography
$description-color:                             #848991;

//Includes
$footer-bg-color:                               $smooth-black;
$menu-separator-color:                          #ffffff40;
$menu-scrolled-bg-color:                        $smooth-black;
$menu-contact-helper-bg-color:                  $smooth-black;

//Blocks
$commercial-card-border-color:                  $gray-border-color;
$commercial-card-hover-color:                   rgba(0, 0, 0, 0.08);

$connected-platform-accordion-bg-color:         #0f0d1840;
$connected-platform-h3-color:                   #0f0d1840;
$connected-platform-description-color:          $smooth-black;

$our-technology-card-bg-color:                  #e0e5ef6e;