.agxano__our-technology {

    .height-helper {
        min-height: 11.0625rem;
    }

    &__cards {
        background-color: $our-technology-card-bg-color;

        .tech-card {
            min-height: 22.875rem;
            position:   relative;

            @include media-breakpoint-up(md) {
                border-bottom: 0.2rem solid $primary;

                * {
                    pointer-events: none;
                }

                &:not(:last-child):after {
                    content:          "";
                    height:           50%;
                    width:            0.06875rem;
                    background-color: $gray-400;
                    position:         absolute;
                    right:            -0.0625rem;
                    bottom:           0;
                }

                .box-overlay {
                    position:         absolute;
                    bottom:           0;
                    left:             0;
                    height:           0;
                    width:            100%;
                    background-color: $primary;
                    opacity:          0;
                    @include transition(0.2s);
                }

                &:hover {
                    .box-overlay {
                        height:  100%;
                        opacity: 1;
                        @include transition(0.2s);
                    }
                }
            }
        }
    }
}