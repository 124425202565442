.agxano__contact-form {
    padding: 7.5rem 0;

    .check-info {
        position: relative;

        img {
            left: -5.2rem;
            top:  -0.5rem;

            @include media-breakpoint-up(lg) {
                left: -6.5rem;
            }
        }
    }
}