* {
    box-sizing: border-box;
}

.margin-helper {
    margin-top: -5rem;
}

textarea {
    resize: none;
}

.card-body {
    padding: 5.375rem 4.1875rem;
}

ul {
    font-size:   1.125rem;
    line-height: 2.25rem;
}

svg text {
    font-family: ProximaNova, sans-serif;
}

a {
    .arrow {
        position: absolute;
        right:    -1.8rem;
        top:      -0.5rem;
        @include transition(0.2s);
    }

    &:hover {

        .arrow {
            transform: translateX(0.7rem);
        }
    }
}

%outline-helper {

    &:active, &:focus {
        outline:            0;
        -moz-outline-style: none;
    }
}

.container {

    @include media-breakpoint-down(lg) {
        padding: 0 2rem;
    }
}

::-webkit-scrollbar {
    height: 0.3125rem;
}

::-webkit-scrollbar-track {
    background: $webkit-scrollbar-track-color;
}

::-webkit-scrollbar-thumb {
    background:    $webkit-scrollbar-thumb-bg-color;
    width:         0.3125rem;
    border-radius: 0.28125rem;
}

::-webkit-scrollbar-thumb:hover {
    background: $webkit-scrollbar-thumb-hover-color;
}

