.agxano__menu {
    display:     flex;
    align-items: center;
    margin-top:  5rem;

    .navbar-brand img {
        max-width: 10.625rem;
    }

    .navbar-toggler {
        @extend %outline-helper;
    }

    .navbar {
        background-color: $black;

        @include media-breakpoint-up(lg) {
            background-color: transparent;
        }

        @include transition(0.2s);

        .nav-link {
            font-family: $font-family-base, sans-serif;
            font-size:   1rem;
            font-weight: $font-weight-bold;
            line-height: 1.5rem;
            color:       $white;

            &:hover {

                &:after {
                    @include transition(0.1s);
                    width: 100%;
                }
            }

            &:after {
                content:     '';
                display:     block;
                width:       0;
                height:      0.1875rem;
                background:  $primary;
                transition:  width .1s;
                padding-top: 0.125rem;
            }

            &.separator {
                position: relative;

                &:before {
                    content:      '';
                    position:     absolute;
                    height:       6.2375rem;
                    top:          -0.85rem;
                    border-right: 0.125rem solid $menu-separator-color;
                    right:        2.4rem;
                    transform:    rotate(90deg);

                    @include media-breakpoint-up(lg) {
                        height:    1.2375rem;
                        top:       0.15rem;
                        right:     -0.1rem;
                        transform: initial;
                    }
                }
            }
        }

        &.scrolled {
            @include transition(0.2s);
            background-color: $menu-scrolled-bg-color;
        }

        &.contact-helper {

            @include media-breakpoint-up(lg) {
                .nav-link {
                    color: $black;
                }

                &.scrolled {
                    @include transition(0.2s);
                    background-color: $menu-contact-helper-bg-color;

                    .nav-link {
                        color: $white;
                    }
                }
            }
        }
    }
}
